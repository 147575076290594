$typo--display-fontsize-sm:     54px
$typo--display-fontsize-md:     86px
$typo--display-fontsize-lg:     104px

$typo--heading-fontsize-sm:     34px
$typo--heading-fontsize-md:     40px
$typo--heading-fontsize-lg:     60px

$typo--title-fontsize-sm:       24px
$typo--title-fontsize-md:       26px
$typo--title-fontsize-lg:       32px

$typo--subtitle-fontsize-sm:    20px
$typo--subtitle-fontsize-md:    22px
$typo--subtitle-fontsize-lg:    25px

$typo--label-fontsize:          14px

p
  margin-bottom: 1.2em

body
  font-size: 18px
  font-family: futura-pt, sans-serif
  font-weight: 300
  line-height: 1.4
  @include media-breakpoint-down(sm)
    font-size: 16px


.typo--display
  font-size: $typo--display-fontsize-sm
  font-weight: 300
  letter-spacing: 1px
  line-height: 1.4
  font-family: kudryashev-d-excontrast, sans-serif
  font-weight: 300
  font-style: normal

  @include media-breakpoint-up(md)
    font-size: $typo--display-fontsize-md
  @include media-breakpoint-up(lg)
    font-size: $typo--display-fontsize-lg


// heading
.typo--heading
  font-size: $typo--heading-fontsize-sm
  font-weight: 300
  letter-spacing: 1px
  line-height: 1.4
  font-family: kudryashev-d-excontrast, sans-serif
  font-weight: 300
  font-style: normal

  @include media-breakpoint-up(md)
    font-size: $typo--heading-fontsize-md
  @include media-breakpoint-up(lg)
    font-size: $typo--heading-fontsize-lg

// title

.typo--title
  font-size: $typo--title-fontsize-sm
  line-height: 1.4
  letter-spacing: 1px
  font-family: kudryashev-d-excontrast, sans-serif
  font-weight: 300
  font-style: normal
  strong, b
    font-weight: 500

  @include media-breakpoint-up(md)
    font-size: $typo--title-fontsize-md
  @include media-breakpoint-up(lg)
    font-size: $typo--title-fontsize-lg

// subtitle

.typo--subtitle
  font-size: $typo--subtitle-fontsize-sm
  font-weight: 300
  line-height: 1.5
  letter-spacing: 0.6px
  strong, b
    font-weight: 500

  @include media-breakpoint-up(md)
    font-size: $typo--subtitle-fontsize-md
  @include media-breakpoint-up(lg)
    font-size: $typo--subtitle-fontsize-lg

// nav item

.typo--nav-item
  font-size: 18px
  line-height: 40px
  @include media-breakpoint-down(sm)
    font-size: 24px
    line-height: 50px

// footer link

.typo--footer-link
  color: $white
  font-size: 12px
  text-transform: uppercase


// label

.typo--label
  font-size: $typo--label-fontsize
  font-weight: 500
  line-height: 1.4
  text-transform: uppercase
  text-decoration: normal
  text-decoration: none !important
  display: inline-block
  width: 100%
  vertical-align: top
  letter-spacing: 3px


//
// Horizontal rules
//

hr
  margin-top: 0
  margin-bottom: 0
  border: 0
  border-top: $hr-border-width solid $hr-border-color


//
// Emphasis
//

small
.small
  font-size: 12px
  font-weight: $font-weight-normal