$bp-lg-up:				1440px
$section-spacing-lg: 	110px
$section-spacing-md: 	90px
$section-spacing-sm: 	80px

// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

//
// Color system
//

$white:     #fff
$gray-100:  #f8f9fa
$gray-200:  #e9ecef
$gray-300:  #dee2e6
$gray-400:  #ced4da
$gray-500:  #adb5bd
$gray-600:  #868e96
$gray-700:  #495057
$gray-800:  #343a40
$gray-900:  #212529
$black:     #18262e

$light:     #f4ecdc

$primary:   #c69c49
$secondary:	lighten(#3f4b52, 20%)


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-base:              		"Graphik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
$font-size-base:                		1rem !default // Assumes the browser default, typically `16px`
$font-size-lg:                  		($font-size-base * 1.25) !default
$font-size-sm:                  		($font-size-base * .875) !default

$font-weight-light:             		300 !default
$font-weight-normal:            		400 !default
$font-weight-bold:              		500 !default

$font-weight-base:              		$font-weight-normal !default
$line-height-base:              		1.5 !default

$headings-margin-bottom:        		1rem
$headings-font-family:          		inherit !default
$headings-font-weight:          		$font-weight-normal
$headings-line-height:          		1.2 !default
$headings-color:                		inherit !default


// Links
//
// Style anchor elements.

$link-color:                    		$primary
$link-decoration:               		none
$link-hover-color:              		$primary
$link-hover-decoration:         		none


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:       		1rem !default


// Navbar

$navbar-nav-link-padding-x:     		25px

$navbar-light-color:					$black
$navbar-light-hover-color:				$black
$navbar-light-disabled-color:			$gray-400
$navbar-light-active-color:				$primary
$navbar-light-toggler-border-color: 	transparent

// hr

$hr-border-color:             			rgba($black, .15) !default


$border-radius:               			0
$border-radius-lg:            			0
$border-radius-sm:            			0