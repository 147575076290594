/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "../../vendor/bootstrap/scss/functions"
@import "../../vendor/bootstrap/scss/variables"
@import "../../vendor/bootstrap/scss/mixins"
@import "../../vendor/bootstrap/scss/root"
@import "../../vendor/bootstrap/scss/reboot"
// @import "../../vendor/bootstrap/scss/type"
// @import "../../vendor/bootstrap/scss/images"
// @import "../../vendor/bootstrap/scss/code"
@import "../../vendor/bootstrap/scss/grid"
// @import "../../vendor/bootstrap/scss/tables"
@import "../../vendor/bootstrap/scss/forms"
@import "../../vendor/bootstrap/scss/buttons"
@import "../../vendor/bootstrap/scss/transitions"
// @import "../../vendor/bootstrap/scss/dropdown"
@import "../../vendor/bootstrap/scss/button-group"
@import "../../vendor/bootstrap/scss/input-group"
@import "../../vendor/bootstrap/scss/custom-forms"
// @import "../../vendor/bootstrap/scss/nav"
// @import "../../vendor/bootstrap/scss/navbar"
@import "../../vendor/bootstrap/scss/card"
// @import "../../vendor/bootstrap/scss/breadcrumb"
// @import "../../vendor/bootstrap/scss/pagination"
// @import "../../vendor/bootstrap/scss/badge"
// @import "../../vendor/bootstrap/scss/jumbotron"
// @import "../../vendor/bootstrap/scss/alert"
// @import "../../vendor/bootstrap/scss/progress"
// @import "../../vendor/bootstrap/scss/media"
// @import "../../vendor/bootstrap/scss/list-group"
// @import "../../vendor/bootstrap/scss/close"
// @import "../../vendor/bootstrap/scss/modal"
// @import "../../vendor/bootstrap/scss/tooltip"
// @import "../../vendor/bootstrap/scss/popover"
// @import "../../vendor/bootstrap/scss/carousel"
@import "../../vendor/bootstrap/scss/utilities"
// @import "../../vendor/bootstrap/scss/print"




.form-control

  // File input buttons theming
  &::file-selector-button
    padding: $input-padding-y $input-padding-x
    margin: (-$input-padding-y) (-$input-padding-x)
    margin-inline-end: $input-padding-x
    color: $black
    background-color: $white
    pointer-events: none
    border-color: inherit
    border-style: solid
    border-width: 0
    border-inline-end-width: $input-border-width
    border-radius: 0
    @include transition($btn-transition)

  &:hover:not(:disabled):not([readonly])::file-selector-button
    background-color: $white

  &::-webkit-file-upload-button
    padding: $input-padding-y $input-padding-x
    margin: (-$input-padding-y) (-$input-padding-x)
    margin-inline-end: $input-padding-x
    color: $black
    background-color: $white
    pointer-events: none
    border-color: inherit
    border-style: solid
    border-width: 0
    border-inline-end-width: $input-border-width
    border-radius: 0
    @include transition($btn-transition)

  &:hover:not(:disabled):not([readonly])::-webkit-file-upload-button
    background-color: $white