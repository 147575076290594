@import "custom-variables" // <--------- Add variables to overwrite Bootstrap variables here
@import "assets/bootstrap" // <--------- Loads Bootstrap v4.1.3

// Components
@import "components/navbar"
@import "components/footer"
@import "components/type"

// Templates

body
  color: $black
  background-color: $light

.menu-icon
  position: fixed
  width: 50px
  height: 50px
  cursor: pointer
  top: 5.4rem
  right: 8rem
  z-index: 20
  transform: scale(1.2)

  div
    margin: auto
    position: absolute
    top: 0
    right: 0
    left: 0
    bottom: 0
    width: 22px
    height: 12px

  span
    position: absolute
    display: block
    width: 100%
    height: 1px
    transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965)
    &:first-of-type
      top: 0
  
    &:last-of-type
      bottom: 0

  &.light
    span
      background-color: $white

  &.dark
    span
      background-color: $black
  

  &.active
    span
      background-color: $black
      &:first-of-type
        transform: rotate(45deg)
        top: 5px
    
      &:last-of-type
        transform: rotate(-45deg)
        bottom: 6px

  @include media-breakpoint-down(sm)
    right: 30px
    top: 60px


  &.active:hover span:first-of-type,
  &.active:hover span:last-of-type
    width: 22px


  &:hover
    // no need hover effect on mobile.
    @media (min-width: 1024px)
      span
        background-color: $primary !important
      span:first-of-type
        width: 26px
    

      span:last-of-type
        width: 12px
  


#hero
  min-height: 100vh
  min-height: 100svh
  width: 100vw
  position: relative


@keyframes animatedBackground
  from
    background-position: 0 0
  to
    background-position: 0 800%

.hero__inner
  min-height: 100vh
  min-height: 100svh
  
  width: 100vw
  background-color: $black
  background-image: url("/dist/images/hero-long.png")
  background-repeat: repeat-y
  background-size: 100%
  padding: 0 30px 80px
  animation: animatedBackground 50s linear infinite forwards
  .hero-headline
    padding-top: 200px
  &:before
    content: ""
    background-image: url("/dist/images/grain.png")
    background-repeat: repeat
    width: 100vw
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    z-index: 2
    pointer-events: none
    opacity: 0.1
    mix-blend-mode: overlay
    background-size: 280px

  @include media-breakpoint-down(sm)
    .hero-headline
      line-height: 1.3 !important
      padding-top: 150px

.hero__arrow
  width: 20px
  height: auto
  margin-left: -10px
  position: absolute
  left: 50%
  bottom: 20px
  @include media-breakpoint-down(sm)
    bottom: 10px


.brand
  width: 115px
  position: absolute
  left: 50%
  top: 3.4rem
  transform: translateX(-50%)
  z-index: 10
  img
    display: block

  @include media-breakpoint-down(sm)
    width: 70px
    top: 2.4rem





.main-nav__collapse
  position: fixed
  z-index: 12
  top: 0
  right: 0
  height: 100vh
  width: 100vw
  visibility: hidden
  pointer-events: none
  opacity: 0
  padding: 40px
  display: flex
  align-items: center
  justify-content: flex-end
  overflow-y: auto
  background-color: $light
  @include media-breakpoint-up(md)
    padding: 150px 15vw

.js--nav-expanded .main-nav__collapse
  visibility: visible
  pointer-events: initial
  
    
.main-nav__collapse__inner
  text-align: right

.main-nav__item
  display: block
  overflow: hidden

.main-nav__link
  position: relative
  color: $black
  text-decoration: none
  font-size: 52px
  line-height: 1
  display: inline-block
  margin-bottom: 8px
  letter-spacing: 1px
  font-family: kudryashev-d-excontrast, sans-serif
  font-weight: 300
  font-style: normal
  transition: all 0.5s cubic-bezier(0.1, 0.82, 0.76, 0.965)
  
  &--sm
    font-size: 32px

  @include media-breakpoint-down(sm)
    font-size: 32px
    margin-bottom: 0

  // &:before
  //   content: ""
  //   height: 2px
  //   width: 0
  //   background: $black
  //   position: absolute
  //   left: 0
  //   bottom: 6px
  //   transition: .2s ease-in-out

  &:hover
    text-decoration: none
    color: $primary
    // &:before
    //   width: 100%




.section
  padding: 150px 30px
  @include media-breakpoint-down(sm)
    padding: 75px 30px

.beliefs
  // background-color: #3f4b52
  background-color: #3a4950


[data-barba-namespace="about"],
[data-barba-namespace="downloads"]
  .menu-icon span
    background-color: $black

  .brand svg *
    fill: $black


.overflow-hidden
  overflow: hidden




.subpage
  padding-top: 270px
  padding-bottom: 150px
  @include media-breakpoint-down(sm)
    padding-top: 200px
    padding-bottom: 100px

.subpage__heading
  margin-bottom: 70px
  @include media-breakpoint-down(sm)
    margin-bottom: 55px







#headingOne a,
#headingTwo a,
#headingThree a
  color: rgba(white, 0.3)
  transition: all 0.3s ease-in-out

  &[aria-expanded="true"]
    color: $white

  @include media-breakpoint-up(lg)
    &:before
      content: ""
      height: 250px
      width: 250px
      border: 2px solid rgba(white, 0.1)
      position: absolute
      z-index: 2
      transition: all 0.3s ease-in-out

    &:hover
      color: $primary
      &:before
        border-color: $primary

    &[aria-expanded="true"]
      color: $white
      &:before
        border-color: $white

@include media-breakpoint-up(lg)
  .accordion-wrap
    height: 400px
    width: 900px
    position: relative

  .accordion
    padding-left: 50%

  #headingOne a
    &:after
      content: ""
      height: 400px
      width: 400px
      // background-color: red
      position: absolute
      left: 0
      top: 0
      z-index: 1

    &:before
      border-radius: 50%
      left: 75px
      top: 0


  #headingTwo a
    &:before
      border-radius: 50%
      left: 0
      top: 150px

  #headingThree a
    &:before
      border-radius: 50%
      left: 150px
      top: 150px



.border-top,
.border-bottom,
.border,
.form-control,
.card
  border-color: #dfd6c4 !important

.text-muted
  color: #afa694!important


.article-image
  @include media-breakpoint-down(sm)
    margin-left: -30px
    margin-right: -30px

.btn
  border-radius: 0

  &:focus,
  &:active
    outline: 0 !important
    box-shadow: none !important


.accordion
  .card:first-of-type:last-of-type
    border-bottom: 1px solid #dfd6c4 !important

  .btn:not(.btn-primary)
    color: $black
    white-space: initial
    text-align: left

    ion-icon
      transition: all 0.3s
      margin-top: 5px
      min-width: 19px
  
    &[aria-expanded="true"]
      ion-icon
        transform: rotate(180deg)

    &:hover
      color: $primary

.add-new-btn[aria-expanded="true"]
  display: none


b, strong
  font-weight: 600


.btn,
.form-control
  font-size: 18px